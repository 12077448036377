.brand-text {
    font-size: x-large;
    padding: 13px 0;
}

img.nav-brand {
    filter: invert(87%) sepia(47%) saturate(1978%) hue-rotate(333deg) brightness(92%) contrast(78%);
}

.logout-button {
    cursor: pointer;
}

.time-display {
    min-height: 21px;
}